._main_div{
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    font-family: 'Oswald', sans-serif !important;
    margin-top: 230px;
}

.heading{
    font-size: 40px;
}